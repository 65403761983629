import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// u and i docs
import { PrivacyPolicyUandI } from "./PrivacyPolicyUandI";
import { TermsAndConditionsUandI } from "./TermsAndConditionsUandI";
import { PrivacyPolicyMobileUandI } from "./PrivacyPolicyMobileUandI";
import { TermsAndConditionsMobileUandI } from "./TermsAndConditionsMobileUandI";

// inaaya ai docs
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsAndConditions } from "./TermsAndConditions";
import { PrivacyPolicyMobile } from "./PrivacyPolicyMobile";
import { TermsAndConditionsMobile } from "./TermsAndConditionsMobile";
import { LandingPage } from "./Landing";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/mobile/privacy-policy" element={<PrivacyPolicyMobile />} />
        <Route path="/mobile/terms-and-conditions" element={<TermsAndConditionsMobile />} />
        <Route path="/uandi/privacy-policy" element={<PrivacyPolicyUandI />} />
        <Route path="/uandi/terms-and-conditions" element={<TermsAndConditionsUandI />} />
        <Route path="/uandi/mobile/privacy-policy" element={<PrivacyPolicyMobileUandI />} />
        <Route path="/uandi/mobile/terms-and-conditions" element={<TermsAndConditionsMobileUandI />} />
      </Routes>
    </Router>
  );
};

export default App;
