import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="w-full bg-gradient-to-r from-[#282b2f] to-[#5a4fcf] text-white shadow-md top-0 z-50 py-5">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8 text-center">
        {/* Footer Links */}
        <div className="flex justify-center space-x-6 mb-4">
          <Link
            to="/privacy-policy"
            className="text-sm md:text-base font-medium text-white hover:text-white transition-colors"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-and-conditions"
            className="text-sm md:text-base font-medium text-white hover:text-white transition-colors"
          >
            Terms & Conditions
          </Link>
        </div>
        {/* Footer Text */}
        <p className="text-sm md:text-base font-light">
          &copy; 2024 I&U SMART TECH LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
