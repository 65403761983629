import React from "react";
import Footer from "./Footer";
import Header from "./Header";

export const PrivacyPolicyMobile = () => {
   // Inline styles as JavaScript objects
   const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    textAlign: "left",
    marginBottom: "200px",
  };

  const titleStyle = {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
    textAlign: "center",
  };

  const sectionTitleStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    margin: "20px 0 10px",
    color: "#444",
  };

  const paragraphStyle = {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#555",
    textAlign: "justify",
    marginBottom: "10px",
  };

  const listStyle = {
    marginLeft: "20px",
    marginBottom: "10px",
  };

  return (
    <>
    
      <div style={containerStyle}>
        <h2 style={titleStyle}>Privacy Policy</h2>
        <p style={paragraphStyle}>Last updated: November, 2024</p>

        <p style={paragraphStyle}>
          <strong>I&U Smart Tech LLC</strong> ("we," "us," or "our") respects
          your privacy and is committed to protecting it through our compliance
          with this Privacy Policy ("Policy"). This Policy describes how we
          collect, use, disclose, and protect your personal information when you
          use our mobile application <strong>"Inaaya AI"</strong> (the "App").
        </p>

        <p style={paragraphStyle}>
          By accessing or using the App, you agree to this Privacy Policy. If
          you do not agree with our policies and practices, do not download,
          register with, or use this App.
        </p>

        <h3 style={sectionTitleStyle}>1. Information We Collect</h3>

        <h4 style={sectionTitleStyle}>A. Information You Provide to Us</h4>
        <p style={paragraphStyle}>
          <strong>Account Information</strong>: When you create an account, we
          collect your name, email address, and password.
        </p>
        <p style={paragraphStyle}>
          <strong>Profile Information</strong>: We collect information about
          your native language, foreign language proficiency levels, and
          language learning goals.
        </p>
        <p style={paragraphStyle}>
          <strong>User Content</strong>: Any content you upload or input into
          the App, including voice and text inputs.
        </p>
        <p style={paragraphStyle}>
          <strong>Customer Support</strong>: Information you provide when you
          contact us for support, such as your name, email address, and the
          content of your communication.
        </p>

        <h4 style={sectionTitleStyle}>B. Information We Collect Automatically</h4>
        <p style={paragraphStyle}>
          <strong>Device Information</strong>: We collect information about your
          device, including device model, operating system, unique device
          identifiers, and mobile network information.
        </p>
        <p style={paragraphStyle}>
          <strong>Usage Information</strong>: Details of your use of the App,
          including traffic data, logs, and other communication data.
        </p>
      
        <p style={paragraphStyle}>
          <strong>Cookies and Similar Technologies</strong>: We use cookies and
          similar technologies to collect data about your device and how you use
          the App.
        </p>

        <h4 style={sectionTitleStyle}>C. Information Collected by Third Parties</h4>
        <p style={paragraphStyle}>
          We use third-party services for analytics and advertising purposes.
          These third parties may collect information sent by your device in
          response to ads or content requests.
        </p>
        <p style={paragraphStyle}>
          <strong>Third-Party Services</strong>:
        </p>
        <ul style={listStyle}>
          <li style={paragraphStyle}>
            <strong>OpenAI, L.L.C</strong>: AI-generated content provider.
            <br />
            <em>Data Collected</em>: User inputs, usage data.
            <br />
            <em>Privacy Policy</em>:{" "}
            <a style={{color: 'blue'}} href="https://openai.com/privacy" target="_blank" rel="noopener noreferrer">
              OpenAI Privacy Policy
            </a>
          </li>
          <li style={paragraphStyle}>
            <strong>Amazon Web Services (AWS)</strong>: Cloud storage provider.
            <br />
            <em>Data Collected</em>: Stored user content, usage data.
            <br />
            <em>Privacy Policy</em>:{" "}
            <a style={{color: 'blue'}} href="https://aws.amazon.com/privacy/" target="_blank" rel="noopener noreferrer">
              AWS Privacy Notice
            </a>
          </li>
         
          <li style={paragraphStyle}>
            <strong>RevenueCat, Inc</strong>: In-app subscription and analytics.
            <br />
            <em>Data Collected</em>: Device identifiers, usage data.
            <br />
            <em>Privacy Policy</em>:{" "}
            <a style={{color: 'blue'}} href="https://www.revenuecat.com/privacy/" target="_blank" rel="noopener noreferrer">
            RevenueCat Privacy Policy
            </a>
          </li>
        </ul>

        <h3 style={sectionTitleStyle}>2. How We Use Your Information</h3>
        <p style={paragraphStyle}>
          We use the information we collect for various purposes, including:
        </p>
        <ul style={listStyle}>
          <li style={paragraphStyle}>
            <strong>To Provide and Maintain the App</strong>: Ensure the App
            functions correctly and provide the services you request.
          </li>
          <li style={paragraphStyle}>
            <strong>To Personalize Your Experience</strong>: Customize content
            and recommendations.
          </li>
          <li style={paragraphStyle}>
            <strong>To Communicate with You</strong>: Send you updates, security
            alerts, and support messages.
          </li>
          <li style={paragraphStyle}>
            <strong>For Analytics and Development</strong>: Analyze usage
            patterns to improve the App.
          </li>
         
          <li style={paragraphStyle}>
            <strong>To Comply with Legal Obligations</strong>: Enforce our terms
            and comply with applicable laws.
          </li>
        </ul>

        <h3 style={sectionTitleStyle}>3. Sharing of Your Information</h3>
        <p style={paragraphStyle}>
          We may share your information in the following situations:
        </p>

        <h4 style={sectionTitleStyle}>A. With Service Providers</h4>
        <p style={paragraphStyle}>
          We share information with third-party vendors who perform services on
          our behalf, such as:
        </p>
        <ul style={listStyle}>
          <li style={paragraphStyle}>
            <strong>Cloud Storage</strong>: Amazon Web Services (AWS)
          </li>
          <li style={paragraphStyle}>
            <strong>AI Content Processing</strong>: OpenAI, L.L.C.
          </li>
         
          <li style={paragraphStyle}>
            <strong>Subscription Management</strong>: RevenueCat, Inc.
          </li>
        </ul>

        <h4 style={sectionTitleStyle}>B. For Analytics</h4>
        <p style={paragraphStyle}>
          We share information with analytics partners to
          improve the App.
        </p>

        <h4 style={sectionTitleStyle}>C. Legal Obligations</h4>
        <p style={paragraphStyle}>
          We may disclose your information if required to do so by law or in
          response to valid requests by public authorities.
        </p>

        <h4 style={sectionTitleStyle}>D. Business Transfers</h4>
        <p style={paragraphStyle}>
          In the event of a merger, acquisition, or asset sale, your personal
          information may be transferred.
        </p>

        <h3 style={sectionTitleStyle}>4. Your Rights and Choices</h3>

        <h4 style={sectionTitleStyle}>A. Access and Correction</h4>
        <p style={paragraphStyle}>
          You may access and update your account information directly within the
          App or by contacting us.
        </p>

        <h4 style={sectionTitleStyle}>B. Opt-Out of Marketing Communications</h4>
        <p style={paragraphStyle}>
          You can opt out of receiving promotional emails by following the
          unsubscribe instructions in those emails or by contacting us.
        </p>


        <h4 style={sectionTitleStyle}>D. Data Deletion</h4>
        <p style={paragraphStyle}>
          You may request deletion of your personal data by contacting us at{" "}
          <a href="mailto:support@iusmarttech.com">support@iusmarttech.com</a>.
        </p>

        <h4 style={sectionTitleStyle}>E. Do Not Sell My Personal Information</h4>
        <p style={paragraphStyle}>
          We do not sell personal information as defined under the CCPA.
          However, you can opt out of certain data sharing practices by
          adjusting your settings.
        </p>

        <h3 style={sectionTitleStyle}>5. Security</h3>
        <p style={paragraphStyle}>
          We implement reasonable security measures to protect your data.
          However, no security system is impenetrable, and we cannot guarantee
          the security of your information.
        </p>

        <h3 style={sectionTitleStyle}>6. International Data Transfers</h3>
        <p style={paragraphStyle}>
          Your information may be transferred to, and processed in, countries
          other than your country of residence. We ensure appropriate safeguards
          are in place to protect your data.
        </p>

        <h3 style={sectionTitleStyle}>7. Children's Privacy</h3>
        <p style={paragraphStyle}>
          The App is not intended for children under the age of 13. We do not
          knowingly collect personal information from children under 13.
        </p>

        <h3 style={sectionTitleStyle}>8. Changes to This Privacy Policy</h3>
        <p style={paragraphStyle}>
          We may update this Privacy Policy from time to time. We will notify
          you of significant changes by posting the new Privacy Policy within
          the App and updating the "Last updated" date.
        </p>

        <h3 style={sectionTitleStyle}>9. Contact Us</h3>
        <p style={paragraphStyle}>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <p style={paragraphStyle}>
          <strong>Email</strong>:{" "}
          <a href="mailto:support@iusmarttech.com">support@iusmarttech.com</a>
         </p>
      </div>
     
    </>
  );
};
