import React from "react";
import "./App.css";
import GooglePlay from "./assets/images/google-store.png";
import ApplePlay from "./assets/images/apple-store.png";
import Screenshot_1 from "./assets/images/1.png";
import Screenshot_2 from "./assets/images/screen-1.png";
import Screenshot_3 from "./assets/images/screen-2.png";

import Footer from "./Footer";
import Header from "./Header";

export const LandingPage = () => {
  return (
    <div className="font-roboto text-white bg-gradient-to-br from-[#1f1c2c] to-[#928dab] flex flex-col min-h-screen">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <header className="flex-grow w-full bg-gradient-to-br from-[#5a4fcf] to-[#282b2f] text-center py-10 md:py-16 animate-fadeIn">
        
        <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row items-center px-4 md:px-8 gap-8 md:gap-16">
          {/* Text Content */}
          <div className="flex-1 text-center md:text-left space-y-4 md:space-y-6 md:max-w-md lg:max-w-lg">
            <h1 className="text-3xl md:text-5xl font-bold uppercase tracking-wide">
              Improve Your Speaking
            </h1>
            <p className="text-sm md:text-lg leading-relaxed opacity-90">
              Discover a new way to learn and explore with our innovative app.
            </p>
          </div>

          {/* Image Gallery with Store Links */}
          <div className="flex flex-col items-center space-y-6">
            {/* Screenshots */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 md:gap-8">
              <img
                src={Screenshot_1}
                alt="Screenshot 1"
                className="w-52 md:w-64 lg:w-72 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 animate-float1"
              />
              <img
                src={Screenshot_2}
                alt="Screenshot 2"
                className="w-52 md:w-64 lg:w-72 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 animate-float2"
              />
              <img
                src={Screenshot_3}
                alt="Screenshot 3"
                className="w-52 md:w-64 lg:w-72 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 animate-float3"
              />
            </div>

            {/* Store Links */}
            <div className="flex flex-wrap justify-center gap-4 mt-6">
              <a
                href="https://play.google.com/store/apps/details?id=com.ausmarttech.inaayaai"
                target="_blank"
                rel="noopener noreferrer"
                className="transform transition-transform duration-300 hover:scale-105"
              >
                <img
                  src={GooglePlay}
                  alt="Get it on Google Play"
                  className="w-32 md:w-36 lg:w-40 rounded-md"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/inaaya-ai/id6738003796"
                target="_blank"
                rel="noopener noreferrer"
                className="transform transition-transform duration-300 hover:scale-105"
              >
                <img
                  src={ApplePlay}
                  alt="Download on the App Store"
                  className="w-32 md:w-36 lg:w-40 rounded-md"
                />
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
